import * as React from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import { Tabs, Tab, Row, Col, Nav, Form, Button } from 'react-bootstrap';
import '../styles/_bootstrap.scss'
import './index.scss';


class Test extends React.PureComponent<{},{}> {
    render() {
        return (
            <>
          <form action="https://formspree.io/sales@direktek.co.uk"  id="existing" method="post" name="existing" role="tabpanel">
                                        <p className="text-center text-XL padding-bottom-L">We&#8217;re always here to support you</p>
                                        <div className="form-group col-sm-6 col-xs-12 form-item left">
                                            <input className="form-control" name="formTitle" type="hidden" value="Existing customer contact form" />
                                            <input className="form-control" name="name" placeholder="Your name" required={true} type="text" />
                                        </div>
                                        <div className="form-group col-sm-6 col-xs-12 form-item">
                                            <input className="form-control" name="_replyto" placeholder="Your email" type="email" />
                                        </div>
                                        <div className="form-group col-sm-6 col-xs-12 form-item left">
                                            <input className="form-control" name="accountRef" placeholder="Company name/code" type="text" />
                                        </div>
                                        <div className="form-group col-sm-6 col-xs-12 form-item">
                                            <select className="form-control div-toggler selector" data-target=".extra-fields" name="nature" style={{ color: "#9e9e9e !important" }}>
                                                <option hidden={true} value="Nature of your query">
                                                    Nature of your query
                                                </option>
                                                <option data-show=".price-query" value="Price query" id="sales@direktek.co.uk" style={{ color: "#3e4143 !important" }}>
                                                    Pricing query
                                                </option>
                                                <option data-show=".where-is" value="Where is my order?" id="sales@direktek.co.uk" style={{ color: "#3e4143 !important" }}>
                                                    Where is my order?
                                                </option>
                                                <option data-show=".missing-parts" value="Missing parts / Technical advice" id="cs@direktek.co.uk" style={{ color: "#3e4143 !important" }}>
                                                    Missing parts/Technical advice
                                                </option>
                                                <option data-show=".return-req" style={{ color: "#3e4143 !important" }}>
                                                    I'd like to return an item
                                                </option>
                                                <option data-show=".accounts-query" value="Accounting query" id="creditcontrol@direktek.co.uk" style={{ color: "#3e4143 !important" }}>
                                                    Accounting query
                                                </option>
                                                <option data-show=".other" value="Other / Not listed" id="sales@direktek.co.uk" style={{ color: "#3e4143 !important" }}>
                                                    Other/Not listed
                                                </option>
                                            </select>
                                        </div>
                                        {/* <!-- EXISTING CUSTOMER - EXTRA FIELDS--> */}
                                        <div className="extra-fields">
                                            <div className="form-group col-sm-6 col-xs-12 price-query missing-parts hide form-item left">
                                                <input className="form-control" name="productCode" placeholder="Model number / Product code" type="text" />
                                            </div>
                                            <div className="form-group col-sm-6 col-xs-12 where-is hide missing-parts form-item left">
                                                <input className="form-control" name="orderRef" placeholder="Order number / PO ref" type="text" />
                                            </div>
                                            <div className="form-group col-sm-6 col-xs-12 accounts-query hide form-item left">
                                                <input className="form-control" name="transactionRef" placeholder="Invoice number / Credit number" type="text" />
                                            </div>
                                            <div className="form-group col-sm-6 col-xs-12 accounts-query hide form-item">
                                                <input className="form-control" name="orderRef" placeholder="Order number / PO ref" type="text" />
                                            </div>
                                            <div className="form-group col-sm-6 col-xs-12 other hide form-item left">
                                                <input className="form-control" name="userDefinedNature" placeholder="Nature of query" type="text" />
                                            </div>
                                            <div className="form-group col-xs-12 price-query where-is accounts-query other missing-parts hide form-item">
                                                <textarea className="form-control" name="message" placeholder="Please provide as much information to help us investiagte your query easier." rows={3}></textarea>
                                            </div>
                                            <div className="form-group col-xs-12 col-sm-6 col-md-4 price-query where-is accounts-query other missing-parts hide form-item left">
                                                <button className="btn btn-success squared send-form" type="submit">Send message</button>
                                            </div>
                                        </div>
                                        <input name="_next" type="hidden" value="/contact" />
                                        <input name="_format" type="hidden" value="plain" />
                                    </form>
            </>
        );
    }
}

export default Test; 